.container,
.btnGroup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container {
  max-width: 300px;
  flex-direction: column;
  padding: 20px;
}

@media (min-width: 900px) {
  .container {
    font-size: 24px;
    max-width: 450px;
  }
}