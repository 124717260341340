.container {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
}

@media (min-width: 900px) {
  .container {
    font-size: 24px;
  }
}